import ReactGA from 'react-ga';

export function initScripts() {
  try {
    if (process.env.GATSBY_GA_ID) {
      ReactGA.initialize(process.env.GATSBY_GA_ID);
    }
  } catch (err) {
    console.log(err, 'err');
  }
}

export function pageview() {
  try {
    if (process.env.GATSBY_GA_ID) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    if (window.fbq) {
      window.fbq('track', 'PageView');
    }
  } catch (err) {
    console.log(err, 'err');
  }
}

export function pixelEvent(name, opts) {
  try {
    if (window.fbq) {
      window.fbq('track', name, opts);
    }
  } catch (pixelError) {
    console.log(pixelError);
  }
}
