/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useStaticQuery, graphql } from "gatsby";
import queryString from 'query-string';
import Cookies from 'js-cookie';
import { getPageAlert, setPageAlert } from '../utils/page-alerts';
import Alert from '../components/alerts/alert';
import Header from "./header";
import Footer from './footer';
import { initScripts } from '../utils/scripts';

import "../css/global.css";

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY);

const Layout = ({ children, hideHeader = false, hideFooter = false }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  useEffect(() => {
    initScripts();
    const parsed = window.location.search ? queryString.parse(window.location.search) : {};
    if (parsed && parsed.r) {
      // We have the referral value, set a cookie
      Cookies.set('rcode', parsed.r, { expires: 1 });
    }
    if (parsed && parsed._ref) {
      // We have the ref value we want to attribute
      Cookies.set('_ref', parsed._ref, { expires: 1 });
    }
  }, []);

  const pageAlert = getPageAlert();
  if (pageAlert) {
    // Only display a page alert once!
    setPageAlert(null);
  }

  // <div
  //   style={{
  //     margin: `0 auto`,
  //     maxWidth: 960,
  //     padding: `0 1.0875rem 1.45rem`,
  //   }}
  // >

  return (
    <div className="font-sans">
      <Elements stripe={stripePromise}>
        <div className="min-h-screen bg-white">
          {!hideHeader && <Header color="gray" siteTitle={data.site.siteMetadata.title} />}

          <div>
            {pageAlert && <div className="container px-4 mx-auto"><Alert {...pageAlert} /></div>}
            <main>{children}</main>
            {!hideFooter && <Footer />}
          </div>
        </div>
      </Elements>
    </div>
  )
}

export default Layout
