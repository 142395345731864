
import React from "react";

function Footer() {
  return (
    <footer className="text-gray-700 body-font pt-10 bg-white">
      <div className="container px-5 py-8 mx-auto">
        <p className="text-sm text-gray-700 sm:py-2 text-center">Receive a gift membership? <a className="font-bold" href="/app/redeem-gift-code">Redeem it now &raquo;</a></p>
        <p className="text-sm text-gray-700 sm:py-2 text-center"><a className="font-bold" href="/coupon-redeem">Redeem a coupon &raquo;</a></p>
        <p className="text-sm text-gray-700 sm:py-2 text-center">Your Information is 100% Secure And Will Never Be Shared With Anyone.</p>
        <p className="text-xs text-gray-700 sm:py-2 mt-4 text-center"><a className="font-bold" href="/terms">Terms</a> | <a className="font-bold" href="/privacy">Privacy Policy</a></p>
        <p className="text-xs text-gray-500 sm:py-2 sm:mt-0 mt-4 text-center">© 2020 | Sidewall Pizza</p>
      </div>
    </footer>
  );
}

export default Footer
