
import React, { useState } from "react";
import { Link } from "gatsby";
import { isLoggedIn } from "../utils/auth";
// import LogoSrc from '../images/swp-logo.png';
// import LogoSrc from '../images/sidewall-logo.png';


function Header() {

  // return <MobileNav />;

  // const mainColor = color || 'blue';

  const buttonClasses = `inline-block mr-2 lg:ml-2 lg:mr-0 text-sm px-4 py-2 leading-none border rounded text-gray-600 border-gray-500 hover:border-transparent hover:text-gray-800 hover:bg-gray-300 mt-4 lg:mt-0`;
  const highlightButtonClasses = `inline-block mr-2 lg:ml-2 lg:mr-0 text-sm px-4 py-2 leading-none border mt-4 lg:mt-0 border-transparent rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:ring-blue active:bg-blue-700 transition ease-in-out duration-150`;

  const linkClasses = `inline-block mr-2 lg:ml-2 lg:mr-0 text-sm px-4 py-2 leading-none text-gray-700 mt-4 lg:mt-0 font-bold`;

  // const isGuide = checkClaim('guide');
  const authorized = isLoggedIn();
  // const isUser = checkClaim('user');

  const noDashboardPages = ['/app/checkout/', '/app/checkout'];
  let hideDashboard = false;
  if (typeof window !== 'undefined') {
    hideDashboard = window && window.location && noDashboardPages.indexOf(window.location.pathname) !== -1;
  }


  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <nav className={`container mx-auto flex items-center justify-between flex-wrap bg-white px-6 lg:px-48 py-3`}>
        <div className="flex items-center flex-shrink-0 text-gray-800 mr-6">
          <Link className="mr-2" to="/"><img src="/sidewall-logo.png" className="h-6 md:h-8" /></Link>
        </div>
        <div className="block lg:hidden">
          <button className={`flex items-center px-3 py-2 border rounded text-gray-600 border-gray-600 hover:text-gray-800 hover:border-gray-500`} onClick={() => setExpanded(!expanded)}>
            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
          </button>
        </div>
        <div className={`w-full block flex-grow lg:flex lg:items-right lg:w-auto ${!expanded && 'hidden'}`}>
          <div className="text-sm lg:flex-grow">

          </div>
          {
            authorized
              ? (
                <div className="">
                  {/* <Link to="/gift-order" className={linkClasses} onClick={() => setExpanded(false)}>Gift a Membership</Link> */}
                  {!hideDashboard && <Link to="/app/dashboard" className={highlightButtonClasses} onClick={() => setExpanded(false)}>Dashboard</Link>}
                  <Link to="/logout" className={buttonClasses} onClick={() => setExpanded(false)}>Logout</Link>
                </div>
              )
              : (
                <div className="">
                  {/* <Link to="/join" className={linkClasses} onClick={() => setExpanded(false)}>Join</Link> */}
                  {/* <Link to="/gift-order" className={linkClasses} onClick={() => setExpanded(false)}>Gift a Membership</Link> */}
                  <Link to="/signin" className={linkClasses} onClick={() => setExpanded(false)}>Login</Link>
                </div>
              )
          }
        </div>
      </nav>
    </>
  );
}

export default Header
