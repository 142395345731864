import jwtDecode from 'jwt-decode';
import moment from 'moment';

const isBrowser = typeof window !== `undefined`;

const getUser = () => window.localStorage.authUser ? JSON.parse(window.localStorage.authUser) : {};
const getJwt = () => window.localStorage.authJwt ? JSON.parse(window.localStorage.authJwt).token : null;

const checkJwtValidity = (jwt) => {
  const decoded = jwtDecode(jwt);
  if (!decoded) return false;
  const now = moment().unix();
  if (now >= decoded.exp) return false;
  return true;
}

export const checkClaim = (perms) => {
  if (!isBrowser) return false;
  const jwt = getJwt();
  if (!jwt) return false;
  const decoded = jwtDecode(jwt);
  if (!decoded) return false;
  if (!checkJwtValidity(jwt)) return false;
  const { claims } = decoded;
  if (!claims) return false;
  // Now check for the permission in the claims array
  const permArray = perms.split('|');
  for (let i = 0; i < permArray.length; i++) {
    const perm = permArray[i];
    if (claims.indexOf(perm) !== -1) return true;
  }
  return false;
}

export const setUser = user => (window.localStorage.authUser = JSON.stringify(user));
export const setJwt = jwt => {
  if (!jwt) {
    window.localStorage.authJwt = JSON.stringify({});
  }
  else {
    window.localStorage.authJwt = JSON.stringify({ token: jwt });
  }
};

export const isLoggedIn = () => {
  if (!isBrowser) return false;
  const jwt = getJwt();
  if (!jwt) return false;
  if (!checkJwtValidity(jwt)) return false;
  const user = getUser();
  return !!user && !!user.id;
}

export const getCurrentUser = () => isBrowser && getUser();
export const getCurrentJwt = () => isBrowser && getJwt();

export const logout = callback => {
  if (!isBrowser) return;
  setUser({});
  setJwt(null);
  if (callback) {
    callback();
  }
}

export const translateRoleIdToName = (id) => {
  switch (id) {
    case '1':
    case 1:
      return "Admin";

    case '3':
    case 3:
      return "Manager";

    case '2':
    case 2:
    default:
      return "Member";

  }
}